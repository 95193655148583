<template>
  <b-card-code>
    <div v-if="$can('read', 'Student')" class="custom-search d-flex">
      <router-link :to="{ name: 'students' }">
        <b-button variant="outline-primary">
          {{ $t("Student List") }}
        </b-button>
      </router-link>
    </div>
    <!-- form -->
    <validation-observer ref="simpleRules">
      <form ref="add_student">
        <b-row>
          <!--Family information section -->
          <div class="col-md-12 form-group mt-2">
            <h4>{{ $t("Student Profie") }}</h4>
          </div>
        </b-row>
        <hr />
        <b-row>
          <b-col md="4">
            <b-card-text>
              <span>{{ $t("Name") }} </span>
            </b-card-text>
            <validation-provider #default="{ errors }" :name="$t('student name')" rules="required">
              <b-form-input name="name" v-model="name" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :placeholder="$t('Enter name')" class="form-control">
              </b-form-input>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col md="4">
            <b-card-text>
              <span>{{ $t("Seat Number") }} </span>
            </b-card-text>
            <validation-provider #default="{ errors }" :name="$t('Enter seat number')">
              <b-form-input name="nick_name" v-model="nick_name" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :placeholder="$t('Write seat number')" class="form-control">
              </b-form-input>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>

          <b-col md="4">
            <b-card-text>
              <span>{{ $t("Student Number") }} </span>
            </b-card-text>
            <validation-provider #default="{ errors }" :name="$t('cadet number')" rules="required">
              <b-form-input name="cadet_no" v-model="cadet_no" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :placeholder="$t('Enter student number')" class="form-control">
              </b-form-input>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>

          <b-col md="4">
            <b-card-text>
              <span>{{ $t("Select Flat") }} </span>
            </b-card-text>
            <validation-provider #default="{ errors }" :name="$t('version')" rules="required">
              <select name="version_id" v-model.number="version_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                class="form-control">
                <option :value="null">{{ $t("Select One") }}</option>
                <option v-for="(version, key) in versions" :value="version.id" :key="key">
                  {{ version.name }}
                </option>
              </select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>

          <b-col md="4">
            <b-card-text>
              <span>{{ $t("Select Class") }}</span>
            </b-card-text>
            <validation-provider #default="{ errors }" :name="$t('class')" rules="required">
              <select name="classes_id" @change="filterSections();" v-model.number="classes_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" class="form-control">
                <option :value="null">{{ $t("Select One") }}</option>
                <option v-for="(cls, key) in classes" :value="cls.id" :key="key">
                  {{ cls.name }}
                </option>
              </select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>

          <b-col md="4">
            <b-card-text>
              <span>{{ $t("Institution") }} </span>
            </b-card-text>
            <validation-provider #default="{ errors }" :name="$t('wing')" rules="required">
              <select name="wing_id" @change="filterSections" v-model.number="wing_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" class="form-control">
                <option :value="null">{{ $t("Select One") }}</option>
                <option v-for="(wing, key) in wings" :value="wing.id" :key="key">
                  {{ wing.name }}
                </option>
              </select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col md="4">
            <b-card-text>
              <span>{{ $t("Student Group") }} </span>
            </b-card-text>
            <validation-provider #default="{ errors }" :name="$t('student group')" rules="required">
              <select name="student_group_id" v-model.number="student_group_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" class="form-control">
                <option :value="null">{{ $t("Select One") }}</option>
                <option v-for="(grp, key) in student_groups" :value="grp.id" :key="key">
                  {{ grp.name }}
                </option>
              </select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col md="4">
            <b-card-text>
              <span>{{ $t("Academic Year") }} </span>
            </b-card-text>
            <validation-provider #default="{ errors }" :name="$t('academic year')" rules="required">
              <select name="academic_year_id" @change="filterSections" v-model.number="academic_year_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" class="form-control">
                <option :value="null">{{ $t("Select One") }}</option>
                <option v-for="(ayear, key) in active_academic_years" :value="ayear.id" :key="key">
                  {{ ayear.year }}
                </option>
              </select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col md="4">
            <b-card-text>
              <span>{{ $t("Section") }} </span>
            </b-card-text>
            <validation-provider #default="{ errors }" :name="$t('section')" rules="required">
              <select name="section_id" v-model.number="section_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                class="form-control">
                <option :value="null">{{ $t("Select One") }}</option>
                <option v-for="(section, key) in filter_sections" :value="section.id" :key="key">
                  {{ section.name }}
                </option>
              </select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col md="4">
            <b-card-text>
              <span>{{ $t("Photo") }} </span>
            </b-card-text>
            <validation-provider #default="{ errors }" :name="$t('student photo')" rules="">
              <b-form-file name="image" v-model="student_photo" accept="image/png|image/jpeg|image/jpg"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :placeholder="$t('Select an image')"
                class="form-control">
              </b-form-file>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>

          <b-col md="4">
            <b-card-text>
              <span>{{ $t("User's Email") }} </span>
            </b-card-text>
            <validation-provider #default="{ errors }" :name="$t('student email')" rules="">
              <b-form-input name="email" v-model="student_email" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :placeholder="$t('Write student email')" class="form-control">
              </b-form-input>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col md="4">
            <b-card-text>
              <span>{{ $t("Phone Number") }} </span>
            </b-card-text>
            <validation-provider #default="{ errors }" :name="$t('student phone number')" rules="">
              <b-form-input name="phone_number" v-model="phone_number"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :placeholder="$t('Write student phone number')"
                class="form-control">
              </b-form-input>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col md="4">
            <b-card-text>
              <span>{{ $t("Date of Birth") }} </span>
            </b-card-text>
            <validation-provider #default="{ errors }" :name="$t('student date of birth')" rules="required">
              <b-form-datepicker name="date_of_birth" v-model="date_of_birth"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :placeholder="$t('Select student date of birth')"
                class="form-control">
              </b-form-datepicker>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col md="4">
            <b-card-text>
              <span>{{ $t("Gender") }} </span>
            </b-card-text>
            <validation-provider #default="{ errors }" :name="$t('gender')" rules="required">
              <select class="form-control" id="gender_id" v-model.number="gender_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" name="gender_id">
                <option :value="null">Select One</option>
                <option v-for="(gender, key) in genders" :value="gender.id" :key="key">
                  {{ gender.name }}
                </option>
              </select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col md="4">
            <b-card-text>
              <span>{{ $t("Religion") }} </span>
            </b-card-text>
            <validation-provider #default="{ errors }" :name="$t('religion')" rules="required">
              <select class="form-control" id="religion_id" v-model.number="religion_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" name="religion_id">
                <option :value="null">Select One</option>
                <option v-for="(religion, key) in active_religions" :value="religion.id" :key="key">
                  {{ religion.name }}
                </option>
              </select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col md="4">
            <b-card-text>
              <span>{{ $t("Blood Group") }} </span>
            </b-card-text>
            <validation-provider #default="{ errors }" :name="$t('blood group')">
              <select class="form-control" id="blood_group" v-model.number="blood_group_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" name="blood_group_id">
                <option :value="null">Select One</option>
                <option v-for="(bgrp, key) in blood_groups" :value="bgrp.id" :key="key">
                  {{ bgrp.name }}
                </option>
              </select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
         
          <b-col md="4">
            <b-card-text>
              <span>{{ $t("Nationality") }} </span>
            </b-card-text>
            <validation-provider #default="{ errors }" :name="$t('student nationality')" rules="required">
              <b-form-input name="nationality" v-model="nationality" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :placeholder="$t('Write student nationality')" class="form-control">
              </b-form-input>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col md="4">
            <b-card-text>
              <span>{{ $t("Previous School Name") }} </span>
            </b-card-text>
            <validation-provider #default="{ errors }" :name="$t('student Previous School Name')" rules="">
              <b-form-input name="previous_school" v-model="previous_school"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :placeholder="$t('Write Previous School Name')" class="form-control">
              </b-form-input>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>

          <b-col md="4">
            <b-card-text>
              <span>Has Sibling?</span>
            </b-card-text>
            <validation-provider #default="{ errors }" name="has_sibling" rules="required">
              <b-form-radio-group v-model="has_sibling" :options="options" stacked name="has_sibling">
              </b-form-radio-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>

          <b-col md="4">
            <b-card-text>
              <span>{{ $t("Student House") }} </span>
            </b-card-text>
            <validation-provider #default="{ errors }" :name="$t('student house')" rules="required">
              <select class="form-control" id="house" v-model="house"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" name="house">

                <option :value="null" selected>Select One</option>
                <option value="MAA2">Maa Manjil 2</option>
              </select>

              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col md="4">
            <b-card-text>
              <span>Preferred Subjects</span>
            </b-card-text>
            <div>
              <input type='checkbox' @click='selectAll' v-model='allSelected'> Select All
              <b-form-checkbox v-for="(subject, key) in subjects" name="compulsory_subjects[]" :value="subject.id"
                @change="updateCheckall(subject)" v-model="selectedSubjects" :key="key">{{ subject.name }}
              </b-form-checkbox>
            </div>
          </b-col>

          <b-col md="4">
            <b-card-text>
              <span>Optional or 4th Subjects</span>
            </b-card-text>
            <div>
              <b-form-checkbox v-for="(subject, key) in fourth_subjects" name="optional_subjects[]" :value="subject.id"
                v-model="optional_subjects" :key="key">{{ subject.name }}</b-form-checkbox>
            </div>
          </b-col>

        </b-row>

        <hr />

        <b-row>
          <div class="col-md-12 form-group mt-2">
            <h4 v-if="has_sibling === 1">{{ $t("Sibling Information") }}</h4>
            <h4 v-else>{{ $t("Family Information") }}</h4>
          </div>
        </b-row>

        <hr />
        <b-row>
          <b-col md="4" v-if="has_sibling === 1">
            <b-card-text>
              <span>{{ $t("Student Number") }}</span>
            </b-card-text>
            <validation-provider #default="{ errors }" name="student id" rules="required">
              <b-form-input name="student_id" id="student_id" v-model="student_id">
              </b-form-input>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <!--Family information section -->
          <div class="row" v-if="has_sibling === 0">
            <b-col md="4">
              <b-card-text>
                <span>{{ $t("Father Name") }} </span>
              </b-card-text>
              <validation-provider #default="{ errors }" :name="$t('father name')" rules="required">
                <b-form-input name="father_name" v-model="father_name"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :placeholder="$t('Write name')"
                  class="form-control">
                </b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col md="4">
              <b-card-text>
                <span>{{ $t("Father Phone Number") }} </span>
              </b-card-text>
              <validation-provider #default="{ errors }" :name="$t('father phone number')" rules="">
                <b-form-input name="father_phone_number" v-model="father_phone_number"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :placeholder="$t('Write phone number')"
                  class="form-control">
                </b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <!-- <b-col md="4">
              <b-card-text>
                <span>{{ $t("Gurdian's Login Name") }} </span>
              </b-card-text>
              <validation-provider #default="{ errors }" :name="$t('gurdian login')" rules="required">
                <b-form-input name="father_email" v-model="father_email"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :placeholder="$t('Write email address')"
                  class="form-control">
                </b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col> -->
            <b-col md="4">
              <b-card-text>
                <span>{{ $t("Father Profession") }} </span>
              </b-card-text>
              <validation-provider #default="{ errors }" :name="$t('father profession')" rules="">
                <b-form-input name="father_profession" v-model="father_profession"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :placeholder="$t('Write profession')"
                  class="form-control">
                </b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col md="4">
              <b-card-text>
                <span>{{ $t("Father NID Number") }} </span>
              </b-card-text>
              <validation-provider #default="{ errors }" :name="$t('father nid number')" rules="">
                <b-form-input type="text" name="father_nid_no" v-model="father_nid_no"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :placeholder="$t('Write NID number')"
                  class="form-control">
                </b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <!-- <b-col md="4">
              <b-card-text>
                <span>{{ $t("Father Monthly Income") }} </span>
              </b-card-text>
              <validation-provider #default="{ errors }" :name="$t('father Monthly Income')" rules="required">
                <b-form-input type="number" name="father_monthly_income" v-model="father_monthly_income"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :placeholder="$t('Write monthly income')"
                  class="form-control">
                </b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col> -->
            <b-col md="4">
              <b-card-text>
                <span>{{ $t("Father Photo") }} </span>
              </b-card-text>
              <validation-provider #default="{ errors }" :name="$t('father photo')" rules="">
                <b-form-file name="father_image" v-model="father_image" accept="image/png|image/jpeg|image/jpg"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :placeholder="$t('Select an image')"
                  class="form-control">
                </b-form-file>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col md="4">
              <b-card-text>
                <span>{{ $t("Mother's Name") }} </span>
              </b-card-text>
              <validation-provider #default="{ errors }" :name="$t('mother name')" rules="required">
                <b-form-input name="mother_name" v-model="mother_name"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :placeholder="$t('Write name')"
                  class="form-control">
                </b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col md="4">
              <b-card-text>
                <span>{{ $t("Mother's Phone Number") }} </span>
              </b-card-text>
              <validation-provider #default="{ errors }" :name="$t('mother phone number')" rules="">
                <b-form-input name="mother_phone_number" v-model="mother_phone_number"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :placeholder="$t('Write phone number')"
                  class="form-control">
                </b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col md="4">
              <b-card-text>
                <span>{{ $t("Mother Profession") }} </span>
              </b-card-text>
              <validation-provider #default="{ errors }" :name="$t('mother profession')" rules="">
                <b-form-input name="mother_profession" v-model="mother_profession"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :placeholder="$t('Write profession')"
                  class="form-control">
                </b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col md="4">
              <b-card-text>
                <span>{{ $t("Mother NID Number") }} </span>
              </b-card-text>
              <validation-provider #default="{ errors }" :name="$t('mother nid number')" rules="">
                <b-form-input type="number" name="mother_nid_no" v-model="mother_nid_no"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :placeholder="$t('Write NID number')"
                  class="form-control">
                </b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <!-- <b-col md="4">
              <b-card-text>
                <span>{{ $t("Mother Monthly Income") }} </span>
              </b-card-text>
              <validation-provider #default="{ errors }" :name="$t('mother Monthly Income')" rules="required">
                <b-form-input type="number" name="mother_monthly_income" v-model="mother_monthly_income"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :placeholder="$t('Write monthly income')"
                  class="form-control">
                </b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col> -->
            <b-col md="4">
              <b-card-text>
                <span>{{ $t("Mother Photo") }} </span>
              </b-card-text>
              <validation-provider #default="{ errors }" :name="$t('mother photo')" rules="">
                <b-form-file name="mother_image" v-model="mother_image" accept="image/png|image/jpeg|image/jpg"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :placeholder="$t('Select an image')"
                  class="form-control">
                </b-form-file>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col md="4">
              <b-card-text>
                <span>{{ $t("Present Address") }} </span>
              </b-card-text>
              <validation-provider #default="{ errors }" :name="$t('present address')" rules="">
                <textarea name="present_address" v-model="present_address" class="form-control" id="present_address"
                  :placeholder="$t('Write present address')"></textarea>

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col md="4">
              <b-card-text>
                <span>{{ $t("Permanent Address") }} </span>
              </b-card-text>
              <validation-provider #default="{ errors }" :name="$t('permanent address')" rules="">
                <textarea name="permanent_address" v-model="permanent_address" class="form-control"
                  id="permanent_address" :placeholder="$t('Write permanent address')"></textarea>

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>

            <b-col md="12">
              <div class="col-md-12 form-group mt-2">
                <h4 v-if="has_sibling === 1">{{ $t("Sibling Information") }}</h4>
                <h4 v-else>{{ $t("Local Gurdian Information") }}</h4>
              </div>
            </b-col>

            <b-col md="4">
              <b-card-text>
                <span>{{ $t("Name") }} </span>
              </b-card-text>
              <validation-provider #default="{ errors }" :name="$t('local guardian name')" rules="">
                <b-form-input name="local_guardian_name" v-model="local_guardian_name"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :placeholder="$t('Write local guardian\'s name')"
                  class="form-control">
                </b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col md="4">
              <b-card-text>
                <span>{{ $t("Contact's Number") }} </span>
              </b-card-text>
              <validation-provider #default="{ errors }" :name="$t('local guardian contact number')" rules="">
                <b-form-input name="local_guardian_phone_number" v-model="local_guardian_phone_number"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :placeholder="$t('Write local guardian\'s contact number')" class="form-control">
                </b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col md="4">
              <b-card-text>
                <span>{{ $t("Permanent Address") }} </span>
              </b-card-text>
              <validation-provider #default="{ errors }" :name="$t('local guardian Permanent address')" rules="">
                <textarea name="l_g_permanent_address" id="local_guardian_address" v-model="l_g_permanent_address"
                  class="form-control" :placeholder="$t('Write permanent address')"></textarea>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col md="4">
              <b-card-text>
                <span>{{ $t("Present Address") }} </span>
              </b-card-text>
              <validation-provider #default="{ errors }" :name="$t('local guardian Present address')" rules="">
                <textarea name="local_guardian_address" id="local_guardian_address" v-model="local_guardian_address"
                  class="form-control" :placeholder="$t('Write present address')"></textarea>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col md="4">
              <b-card-text>
                <span>{{ $t("Occupation") }} </span>
              </b-card-text>
              <validation-provider #default="{ errors }" :name="$t('occupation')">
                <b-form-input name="l_g_occupation" v-model="l_g_occupation"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :placeholder="$t('Occupation')"
                  class="form-control">
                </b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col md="4">
              <b-card-text>
                <span>{{ $t("Relation with student") }} </span>
              </b-card-text>
              <validation-provider #default="{ errors }" :name="$t('relation with student')" rules="">
                <b-form-input name="relation" v-model="relation" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :placeholder="$t('Write relation with student')" class="form-control">
                </b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <!--Family information section end-->
          </div>
          <!-- submit button -->
          <b-col md="12" class="mt-2">
            <b-button :class="is_processing ? 'disabled' : ''" variant="primary" type="submit"
              @click.prevent="saveResident">
              Submit
            </b-button>
          </b-col>
        </b-row>
      </form>
    </validation-observer>
    <!--  sibling information        -->
  </b-card-code>
</template>

<script>
import BCardCode from "@core/components/b-card-code";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BRow,
  BCol,
  BButton,
  BCardText,
  BFormFile,
  BFormInput,
  BFormDatepicker,
  BFormRadioGroup,
  BFormCheckbox
} from "bootstrap-vue";
import { required, integer, email } from "@validations";
import constants from "@core/utils/constants";
import apiCall from "@/libs/axios";
import { mapGetters } from "vuex";
import moment from "moment";
export default {
  name: "StudentAdd",
  components: {
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BFormFile,
    BFormInput,
    BCol,
    BFormDatepicker,
    BButton,
    BCardText,
    BFormRadioGroup,
    BFormCheckbox
  },
  data() {
    return {
      moment,
      options: [
        { text: "Yes", value: 1 },
        { text: "No", value: 0 },
      ],
      is_processing: false,
      required,
      integer,
      email,
      family: {},
      student_id: "", //if sibling then this is sibling student id
      student_photo: null,
      student_group_id: null,
      version_id: null,
      // shift_id: null,
      classes_id: null,
      class_day_id: null,
      class_period_id: null,
      academic_year_id: null,
      wing_id: null,
      section_id: null,
      name: "",
      nick_name: "",
      cadet_no: "",
      house: null,
      nationality: "",
      previous_school: "",
      student_email: "",
      phone_number: "",
      date_of_birth: "",
      religion_id: null,
      gender_id: null,
      blood_group_id: null,
      has_sibling: 0,
      relation: "",
      father_name: "",
      father_email: "",
      father_phone_number: "",
      father_profession: "",
      father_nid_no: "",
      father_image: null,
      mother_name: "",
      mother_phone_number: "",
      mother_profession: "",
      mother_nid_no: "",
      mother_image: null,
      present_address: "",
      permanent_address: "",
      local_guardian_address: "",
      local_guardian_name: "",
      father_monthly_income: 0,
      mother_monthly_income: 0,
      l_g_permanent_address: "",
      l_g_occupation: "",
      local_guardian_phone_number: "",
      classes: [],
      student_groups: [],
      wings: [],
      all_sections: [],
      all_subjects: [],
      filter_sections: [],
      filtered_subjects: [],
      preferred_subjects: [],
      optional_subjects: [],
      allSelected: false,
      selectedSubjects: []
    };
  },
  created() {
    this.getAll();
    if (this.$store.getters.active_academic_years < 1) {
      this.$store.dispatch("GET_ACTIVE_ACADEMIC_YEAR");
    }

    if (this.$store.getters.active_religions < 1) {
      this.$store.dispatch("GET_ALL_ACTIVE_RELIGION");
    }

    if (this.$store.getters.blood_groups < 1) {
      this.$store.dispatch("GET_ALL_BLOOD_GROUP");
    }

    if (this.$store.getters.genders < 1) {
      this.$store.dispatch("GET_ALL_GENDER");
    }

    if (this.$store.getters.subjects < 1) {
      this.$store.dispatch("GET_ALL_SUBJECT");
    }
    if (this.$store.getters.versions < 1) {
      this.$store.dispatch("GET_ALL_VERSION");
    }
    
  },
  methods: {
    selectAll: function () {
      this.selectedSubjects = [];
      this.allSelected = !this.allSelected;
      if (this.allSelected) {
        for (var subject in this.subjects) {
          this.selectedSubjects.push(this.subjects[subject].id.toString());
        }
        this.compulsory_subjects = this.selectedSubjects;
      }
    },

    updateCheckall(subject) {
      if (this.subjects.length == this.selectedSubjects.length) {
        this.allSelected = true;
      } else {
        this.allSelected = false;
        this.selectedSubjects.pop(this.subjects[subject].id.toString());
        this.compulsory_subjects = this.selectedSubjects;
      }
    },

    saveResident() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success && !this.is_processing) {
          this.is_processing = true;
          let data = new FormData(this.$refs.add_student);
          apiCall
            .post("/student/information/store", data)
            .then((response) => {
              if (response.data.status === "success") {
                this.$toaster.success(response.data.message);
                this.$refs.add_student.reset();
                this.$refs.simpleRules.reset();
                this.is_processing = false;
              } else {
                this.$toaster.error(response.data.message);
                this.is_processing = false;
              }
            })
            .catch((error) => {
              this.is_processing = false;
              if (error.response.status === 422) {
                Object.keys(error.response.data.errors).map((field) => {
                  this.$toaster.error(error.response.data.errors[field][0]);
                });
              } else this.$toaster.error(error.response.data.message);
            });
        }
      });
    },

    /*
     * method for get all active class wise version, shift, student groups
     * */
    async getAll() {
      await apiCall
        .get("/get/active/class/with/v/s/sg/sections")
        .then((response) => {
          this.classes = response.data;
        })
        .catch(() => {
          this.classes = [];
        });
    },
    siblingFind() {
      apiCall
        .get("/student/sibling/find", this.student_id)
        .then((response) => {
          this.family = response.data;
        })
        .catch((error) => {
          this.family = {};
          this.$toaster.error(error);
        });
    },
    /*
     * method for filter sections
     * */
    filterSections() {
      this.section_id = null;
      if (!this.wing_id || !this.academic_year_id) this.filter_sections = [];
      else {
        this.filter_sections = this.all_sections
          .map((item) => {
            if (
              parseInt(item.pivot.wing_id) === this.wing_id &&
              parseInt(item.pivot.academic_year_id) === this.academic_year_id
            )
              return item;
          })
          .filter(Boolean);
      }
    },
  },
  computed: {
    ...mapGetters([
      "active_academic_years",
      "active_religions",
      "genders",
      "blood_groups",
      'versions'
    ]),

    subjects() {
      let subjectList = this.$store.getters.subjects;
      return subjectList.filter(
        item => item.classes_id == parseInt(this.classes_id) &&
          (item.student_group_id == parseInt(this.student_group_id) ||
            parseInt(item.student_group_id) === parseInt(constants.COMMON_GROUP_ID)));
    },

    fourth_subjects() {
      let subjectList = this.$store.getters.subjects;
      return subjectList.filter(
        item => item.classes_id == parseInt(this.classes_id) &&
          (item.student_group_id == parseInt(this.student_group_id) ||
            parseInt(item.student_group_id) === parseInt(constants.COMMON_GROUP_ID)) &&
          item.is_optional == 1);
    }

  },
  watch: {
    /*watcher for class on change*/
    classes_id() {
      let s_classes = this.classes.find(
        (item) => parseInt(item.id) === this.classes_id
      );

       //this.version_id = null;
      // this.shift_id = null;
      this.wing_id = null;
      this.student_group_id = null;
      if (s_classes) {
       //this.versions = s_classes.versions ?? [];
        // this.shifts = s_classes.shifts ?? [];
        this.wings = s_classes.wings ?? [];
        this.student_groups = s_classes.student_groups ?? [];
        this.all_sections = s_classes.sections ?? [];
        this.all_subjects = s_classes.subjects ?? [];

        this.filtered_subjects = this.all_subjects
          .map((item) => {
            if (
              parseInt(item.classes_id) === this.classes_id)
              return item;
          })
          .filter(Boolean);

      } else {
       // this.versions = [];
        // this.shifts = [];
        this.wings = [];
        this.student_groups = [];
        this.all_sections = [];
        this.all_subjects = [];
      }
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
